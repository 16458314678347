// @flow

import type { Element } from 'react';
import { LetterState } from './Letter';
import '../css/Keyboard.css';

const layout: string[][] = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['\u{21A9}', 'z', 'x', 'c', 'v', 'b', 'n', 'm', '\u{232B}'],
];

function getClassForLetterState(letterState: ?LetterState): string {
  switch (letterState ?? LetterState.EMPTY) {
    case LetterState.CORRECT:
      return 'Keyboard-correct';
    case LetterState.WRONG:
      return 'Keyboard-wrong';
    case LetterState.WRONG_PLACE:
      return 'Keyboard-wrong-place';
    case LetterState.EMPTY:
      return '';
  }
}

export function Keyboard({
  handleLetterClick,
  letters,
}: {
  handleLetterClick: (SyntheticEvent<HTMLButtonElement>) => void,
  letters: Map<string, LetterState>,
}): Element<any> {
  return (
    <div className="Keyboard-container">
      {layout.map((row, i) => (
        <div key={i} className="Keyboard-row">
          {row.map((letter) => {
            const classArr = ['Keyboard-letter'];
            if (!/^([a-z]{1})$/.test(letter)) {
              classArr.push('control');
            }
            if (letters.has(letter)) {
              classArr.push(getClassForLetterState(letters.get(letter)));
            }
            return (
              <button
                type="button"
                className={classArr.join(' ')}
                key={letter}
                onClick={handleLetterClick}
                data-letter={letter}
              >
                {letter}
              </button>
            );
          })}
        </div>
      ))}
    </div>
  );
}
