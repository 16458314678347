// @flow
// consider https://github.com/entwicklerstube/babel-plugin-root-import for imports
import '../css/Letter.css';
import type { Element } from 'react';

export enum LetterState {
  CORRECT,
  EMPTY,
  WRONG,
  WRONG_PLACE,
}

type Props = {
  letter: ?string,
  state: LetterState,
  startGameOverAnimation?: ?() => void,
  // for animation
  // TODO: make this nicer.  maybe it's own object.
  column?: number,
  newInput?: boolean,
  gameOver?: boolean,
};

function Letter(props: Props): Element<any> {
  const colorClass = (() => {
    switch (props.state) {
      case LetterState.CORRECT:
        return 'green';
      case LetterState.WRONG:
        return 'gray';
      case LetterState.WRONG_PLACE:
        return 'yellow';
      case LetterState.EMPTY:
        return 'black';
    }
  })();

  const columnClass = (() => {
    // yes, i know this looks clowny
    switch (props.column) {
      case 0:
        return 'one';
      case 1:
        return 'two';
      case 2:
        return 'three';
      case 3:
        return 'four';
      case 4:
        return 'five';
      default:
        return '';
    }
  })();

  // TODO: should these be mutex?
  const animationClasses = [];
  if (props.newInput === true) {
    animationClasses.push('Letter-newInput');
  }
  if (props.gameOver === true) {
    animationClasses.push('Letter-gameOver');
  }
  if (props.newInput !== true && props.letter != null) {
    animationClasses.push('Letter-flip-animation');
  }

  return (
    // TODO this is an ugly way to set the gameover animation
    <div onAnimationEnd={props.startGameOverAnimation ?? null}>
      <div
        className={`Letter-square ${colorClass} ${columnClass} ${animationClasses.join(
          ' '
        )}`}
      >
        <div className="Letter-letter">{props.letter?.toUpperCase()}</div>
      </div>
    </div>
  );
}

export default Letter;
export function letterStateToEmoji(letterState: LetterState): string {
  switch (letterState) {
    case LetterState.CORRECT:
      // green square
      return '\u{1F7E9}';
    case LetterState.EMPTY:
      // don't return anything for empties
      return '';
    case LetterState.WRONG:
      return '\u2B1B';
    case LetterState.WRONG_PLACE:
      return '\u{1F7E8}';
  }
  return 'bug';
}
