// @flow
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      history {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSolution = /* GraphQL */ `
  query GetSolution($id: ID!) {
    getSolution(id: $id) {
      id
      date
      user {
        id
        name
        createdAt
        updatedAt
      }
      puzzle {
        id
        date
        answer
        createdAt
        updatedAt
      }
      board {
        id
        letter
        state
      }
      createdAt
      updatedAt
      userHistoryId
      puzzleSolutionId
    }
  }
`;
export const listSolutions = /* GraphQL */ `
  query ListSolutions(
    $filter: ModelSolutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSolutions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        createdAt
        updatedAt
        userHistoryId
        puzzleSolutionId
      }
      nextToken
    }
  }
`;
export const getPuzzle = /* GraphQL */ `
  query GetPuzzle($id: ID!) {
    getPuzzle(id: $id) {
      id
      date
      answer
      solution {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPuzzles = /* GraphQL */ `
  query ListPuzzles(
    $filter: ModelPuzzleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPuzzles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        answer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
