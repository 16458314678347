// @flow
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      history {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      history {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      history {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSolution = /* GraphQL */ `
  mutation CreateSolution(
    $input: CreateSolutionInput!
    $condition: ModelSolutionConditionInput
  ) {
    createSolution(input: $input, condition: $condition) {
      id
      date
      user {
        id
        name
        createdAt
        updatedAt
      }
      puzzle {
        id
        date
        answer
        createdAt
        updatedAt
      }
      board {
        id
        letter
        state
      }
      createdAt
      updatedAt
      userHistoryId
      puzzleSolutionId
    }
  }
`;
export const updateSolution = /* GraphQL */ `
  mutation UpdateSolution(
    $input: UpdateSolutionInput!
    $condition: ModelSolutionConditionInput
  ) {
    updateSolution(input: $input, condition: $condition) {
      id
      date
      user {
        id
        name
        createdAt
        updatedAt
      }
      puzzle {
        id
        date
        answer
        createdAt
        updatedAt
      }
      board {
        id
        letter
        state
      }
      createdAt
      updatedAt
      userHistoryId
      puzzleSolutionId
    }
  }
`;
export const deleteSolution = /* GraphQL */ `
  mutation DeleteSolution(
    $input: DeleteSolutionInput!
    $condition: ModelSolutionConditionInput
  ) {
    deleteSolution(input: $input, condition: $condition) {
      id
      date
      user {
        id
        name
        createdAt
        updatedAt
      }
      puzzle {
        id
        date
        answer
        createdAt
        updatedAt
      }
      board {
        id
        letter
        state
      }
      createdAt
      updatedAt
      userHistoryId
      puzzleSolutionId
    }
  }
`;
export const createPuzzle = /* GraphQL */ `
  mutation CreatePuzzle(
    $input: CreatePuzzleInput!
    $condition: ModelPuzzleConditionInput
  ) {
    createPuzzle(input: $input, condition: $condition) {
      id
      date
      answer
      solution {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePuzzle = /* GraphQL */ `
  mutation UpdatePuzzle(
    $input: UpdatePuzzleInput!
    $condition: ModelPuzzleConditionInput
  ) {
    updatePuzzle(input: $input, condition: $condition) {
      id
      date
      answer
      solution {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePuzzle = /* GraphQL */ `
  mutation DeletePuzzle(
    $input: DeletePuzzleInput!
    $condition: ModelPuzzleConditionInput
  ) {
    deletePuzzle(input: $input, condition: $condition) {
      id
      date
      answer
      solution {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
