// @flow
import React, { useEffect, useMemo, useState, useRef } from 'react';
import '../css/App.css';
import Letter, { LetterState } from '../components/Letter';
import Board from '../components/Board';
import TestGraphQL from '../components/TestGraphQL';
import type { ComponentType, Element } from 'react';
import { ToastProvider } from './ToastProvider';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  Authenticator,
  useAuthenticator,
  //  $FlowIssue SO:70382924
} from '@aws-amplify/ui-react';

import { Auth } from 'aws-amplify';

// $FlowIssue
import textfile from '../assets/five_letter_words.txt';
import { Keyboard } from './Keyboard';
import { Debadordle } from './Debadordle';
import BSODPage from './BSODPage';

const WORDLE_ROOT = '/wordle';

function App(): React$Node {
  const [searchParams, _] = useSearchParams();
  const [loggedInEmail, setLoggedInEmail] = useState<?string>(null);

  // TODO: move this stuff to Debadordle
  const [answer, updateAnswer] = useState(() => localStorage.getItem('answer'));
  const words = useMemo<Array<string>>(() => textfile.split('\n').slice(1), []);
  const location = useLocation();

  const dialogRef = useRef(null);
  const dialogContentRef = useRef(null);
  useCloseOnClickOutsideDialog(dialogRef, dialogContentRef);

  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  useEffect(() => {
    setLoggedInEmail(user?.attributes?.email);
    if (authStatus === 'authenticated') {
      dialogRef?.current?.close();
    }
  }, [authStatus, user]);

  useEffect(() => {
    if (answer != null) {
      return;
    }

    const newAnswer = words[Math.floor(Math.random() * words.length)];
    updateAnswer(newAnswer);
    localStorage.setItem('answer', newAnswer);
  }, [words, answer]);

  if (location.pathname !== WORDLE_ROOT) {
    return <BSODPage />;
  }

  console.log(answer);
  if (answer == null) {
    return <div>loading...</div>;
  }

  const clearData = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target;
    if (target instanceof HTMLButtonElement) {
      // prevent 'enter' from clearing data again
      target.blur();
    }
    localStorage.clear();
    const newAnswer = words[Math.floor(Math.random() * words.length)];
    updateAnswer(newAnswer);
  };

  const authDialog = (
    <dialog ref={dialogRef} className="App-auth">
      <div ref={dialogContentRef}>
        <button
          className="App-authclose"
          onClick={() => dialogRef?.current?.close()}
        >
          close
        </button>
        {/* TODO: need to subscribe to authenticator success event
        and then close the dialog */}
        <Authenticator />
      </div>
    </dialog>
  );

  return (
    <div className="App">
      {loggedInEmail ? (
        <div>
          Logged in as: {loggedInEmail}
          <button
            type="button"
            className="App-signOut"
            onClick={() => Auth.signOut()}
          >
            Sign Out
          </button>
        </div>
      ) : (
        <button
          type="button"
          className="App-signIn"
          onClick={() => dialogRef?.current?.showModal()}
        >
          Sign In
        </button>
      )}
      {searchParams.get('debug') === '1' ? <TestGraphQL /> : null}
      <ToastProvider>
        <div className="App-main">
          <Debadordle key={answer} answer={answer} bank={words} />
          <button className="App-clear" type="button" onClick={clearData}>
            clear data
          </button>
        </div>
      </ToastProvider>
      {authDialog}
    </div>
  );
}

// from https://reactrouter.com/en/6.5.0/start/faq
import { useNavigate, useParams } from 'react-router-dom';
function withRouter(Component: ComponentType<{}>) {
  function ComponentWithRouterProp(props: {}) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

// based on SO: 32553158
function useCloseOnClickOutsideDialog(dialogRef, dialogContentRef) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (!dialogRef.current?.open) {
        return;
      }
      if (!dialogContentRef.current.contains(event.target)) {
        dialogRef.current.close();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dialogRef, dialogContentRef]);
}

export default (withRouter(App): ComponentType<{}>);
