// @flow
import React, { createContext, useState } from 'react';
import type { Element, ChildrenArray } from 'React';
import '../css/Toast.css';

// TODO: is this typing right?  can it be simpler?
export const ToastContext: React$Context<
  (message: string, onToastComplete?: () => void) => void
> = createContext<(string) => void>(() => {});

export function ToastProvider({
  children,
}: {
  children: ChildrenArray<any>,
}): Element<any> {
  const [toastMessage, updateToastMessage] =
    useState<?string>('default message');
  const [showToast, updateShowToast] = useState(false);

  function toast(message: string, onToastComplete?: () => void): void {
    updateToastMessage(message);
    updateShowToast(true);

    setTimeout(() => {
      updateShowToast(false);
      updateToastMessage(null);
      onToastComplete && onToastComplete();
    }, 3000);
  }

  const maybeToast = showToast ? (
    <div className="Toast-container">
      <div className="Toast-content">{toastMessage}</div>
    </div>
  ) : null;

  return (
    <ToastContext.Provider value={toast}>
      {maybeToast}
      {children}
    </ToastContext.Provider>
  );
}
