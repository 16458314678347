// @flow

import type { Element } from 'react';

import BSOD from '../assets/BSOD.png';
import '../css/BSODPage.css';

import React from 'react';

export default function BSODPage(): Element<any> {
  return (
    <div className="BSODPage-Container">
      <img className=".BSODPage-Image" src={BSOD} />
    </div>
  );
}
