// @flow

import type { LetterData } from '../Debadordle';

type BoardStorage = {
  grid?: Array<Array<?LetterData>>,
  showShareButton?: boolean,
  curRow?: number,
};

export function updateLocalStorageBoard(newValues: BoardStorage): void {
  if (newValues.grid != null) {
    localStorage.setItem('grid', JSON.stringify(newValues.grid));
  }
  if (newValues.showShareButton === true) {
    localStorage.setItem(
      'showShareButton',
      newValues.showShareButton.toString()
    );
  }

  if (newValues.curRow != null) {
    localStorage.setItem('curRow', newValues.curRow.toString());
  }
}

export function getLocalStorageBoard(): BoardStorage {
  let boardStorage = ({}: BoardStorage);
  const localGrid = localStorage.getItem('grid');
  if (localGrid != null) {
    boardStorage.grid = JSON.parse(localGrid);
  }

  const localShowShareButton = localStorage.getItem('showShareButton');
  if (localShowShareButton != null) {
    boardStorage.showShareButton = localShowShareButton === 'true';
  }

  const curRow = localStorage.getItem('curRow');
  if (curRow != null) {
    boardStorage.curRow = parseInt(curRow);
  }

  return boardStorage;
}
