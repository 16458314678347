// @flow

import type { ListPuzzlesQuery } from '../AmplifyAPI';
// $FlowIssue grrr.
import type { GraphQLResult } from 'aws-amplify';
import type { Element } from 'react';

import { createPuzzle as graphQLCreatePuzzleMutation } from '../graphql/mutations';
import { listPuzzles } from '../graphql/queries';

// $FlowIssue grrr.
import { API, graphqlOperation } from 'aws-amplify';
import { useEffect, useState } from 'react';

// annoying that i dont have access to the Puzzle object directly, but wtvr
type Puzzle = $NonMaybeType<ListPuzzlesQuery['listPuzzles']?.['items'][0]>;

export default function (): Element<any> {
  const createPuzzle = async () => {
    console.log('creat puizzle');
    const puzzleDetails = {
      date: 1671551834,
      answer: 'wordy',
    };

    const newPuzzle = await API.graphql({
      query: graphQLCreatePuzzleMutation,
      variables: { input: puzzleDetails },
    });
    console.log(newPuzzle);
  };

  const [puzzles, updatePuzzles] = useState<Puzzle[]>([]);

  useEffect(() => {
    async function fetchPuzzles(): GraphQLResult<any> {
      const response = await API.graphql(graphqlOperation(listPuzzles));
      console.log(response?.data?.listPuzzles?.items);
      updatePuzzles(response?.data?.listPuzzles?.items);
    }

    fetchPuzzles();
  }, []);

  return (
    <>
      <button type="button" onClick={createPuzzle}>
        create puzzle
      </button>
      {puzzles.map((x, i) => (
        <div key={i}>{x.answer}</div>
      ))}
    </>
  );
}
