// @flow
import type { Element } from 'react';

import Board from './Board';
import { Keyboard } from './Keyboard';
import { LetterState } from './Letter';
import { ToastContext } from './ToastProvider';
import {
  getLocalStorageBoard,
  updateLocalStorageBoard,
} from './util/boardStorageUtil';
import {
  copyText,
  getKeyboardLetterStatesFromGrid,
  getResultForGuessedLetter,
  getWinMessage,
} from './util/boardUtils';

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export type LetterData = {
  letterState: LetterState,
  letter: string,
  newInput: boolean,
};

type DebadordleInput =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z'
  | 'enter'
  | 'back';

/**
 *
 * Start Game Component
 *
 */

export function Debadordle({
  answer,
  bank,
}: {|
  answer: string,
  bank: string[],
|}): Element<any> {
  // only load from storage once
  const boardStorage = useMemo(() => getLocalStorageBoard(), []);
  const toast = useContext(ToastContext);

  const [animationClass, updateAnimationClass] = useState('');
  function shakeRow() {
    updateAnimationClass('Board-shake');
  }

  function resetAnimation() {
    updateAnimationClass('');
  }

  const [gameOver, _setGameOver] = useState(false);
  const gameOverRef = useRef(gameOver);
  function setGameOver(isGameOver: boolean) {
    gameOverRef.current = isGameOver;
    _setGameOver(isGameOver);
  }

  const [grid, _updateGrid] = useState<Array<Array<?LetterData>>>(() => {
    if (boardStorage.grid != null) {
      return boardStorage.grid;
    }
    const gridInit = [];
    for (let i = 0; i < 6; i++) {
      gridInit.push([]);
      for (let j = 0; j < 5; j++) {
        gridInit[i].push(null);
      }
    }
    return gridInit;
  });

  // Create a ref
  const gridRef = useRef(grid);
  // And create our custom function in place of the original setActivePoint
  function updateGrid(grid: Array<Array<?LetterData>>) {
    gridRef.current = grid; // Updates the ref
    _updateGrid(grid);
  }

  const [currentRow, _updateRow] = useState(() => boardStorage.curRow ?? 0);
  const rowRef = useRef(currentRow);
  function updateRow(col: number) {
    rowRef.current = col;
    _updateRow(col);
  }

  const [currentCol, _updateCol] = useState(0);
  const colRef = useRef(currentCol);
  function updateCol(col: number) {
    colRef.current = col;
    _updateCol(col);
  }

  const handleEnter = useCallback(
    (
      curCol: number,
      curRow: number,
      updateLetter: (row: number, col: number, value: ?LetterData) => void
    ) => {
      // Enter case 1: ensure 5 letters
      if (curCol !== 5 || grid[curRow][4]?.letter == null) {
        console.log('incomplete word');
        shakeRow();
        return;
      }

      // Enter case 2: ensure in our word bank
      const fullGuess = grid[curRow].map((data) => data?.letter ?? '').join('');
      if (!bank.includes(fullGuess)) {
        shakeRow();
        console.log('not in bank');
        return;
      }

      // Enter case 3: score the guessed letters
      for (let col = 0; col < 5; col++) {
        const guessedLetter = grid[curRow][col]?.letter ?? '';
        const result = getResultForGuessedLetter(grid[curRow], col, answer);
        updateLetter(curRow, col, {
          letter: guessedLetter,
          letterState: result,
          newInput: false,
        });
      }

      updateRow(curRow + 1);
      updateCol(0);
      updateLocalStorageBoard({ grid, curRow: rowRef.current });

      // check for a win
      if (answer === grid[curRow].map((data) => data?.letter ?? '').join('')) {
        // toast is set after animation.
        // TODO- again, this is a terrible way to do this
        setGameOver(true);
      }
    },
    [answer, bank, grid]
  );

  const handleDebadordleInput = useCallback(
    (input: DebadordleInput): void => {
      const grid = gridRef.current;
      const curCol = colRef.current;
      const curRow = rowRef.current;
      const gameOver = gameOverRef.current;

      if (gameOver === true) {
        return;
      }

      // here bc of usecallback deps (need it be?)
      const updateLetter = (
        row: number,
        col: number,
        value: ?LetterData
      ): void => {
        const copyGrid = [...grid];
        copyGrid[row][col] = value;
        updateGrid(copyGrid);
      };

      // case 1: Backspace
      if (input === 'back') {
        if (curCol !== 0) {
          updateLetter(curRow, curCol - 1, null);
          updateCol(curCol - 1);
        }
        return;
      }

      // case 2: Enter
      if (input === 'enter') {
        handleEnter(curCol, curRow, updateLetter);
        return;
      }

      // case 3: no room for letter
      if (curCol > 4) {
        return;
      }

      // case 4: Letter
      updateLetter(curRow, curCol, {
        letterState: LetterState.EMPTY,
        letter: input,
        newInput: true,
      });
      updateCol(curCol + 1);
    },
    [handleEnter]
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      event.stopPropagation();
      if (event.metaKey || event.ctrlKey) {
        return;
      }

      // backspace
      if (event.keyCode === 8) {
        handleDebadordleInput('back');
        // enter
      } else if (event.keyCode === 13) {
        handleDebadordleInput('enter');
        // valid letter
      } else if (event.key.match(/^[a-z]$/i)) {
        // nasty typing, but worth it, i think.
        // https://flow.org/en/docs/types/casting/#toc-type-casting-through-any
        handleDebadordleInput(((event.key: any): DebadordleInput));
      }
    },
    [handleDebadordleInput]
  );

  // register keydown listener
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  // keyboard listener
  function handleLetterClick(event: SyntheticEvent<HTMLButtonElement>): void {
    const input = event.currentTarget.getAttribute('data-letter');
    if (input === '\u{21A9}') {
      handleDebadordleInput('enter');
    } else if (input === '\u{232B}') {
      handleDebadordleInput('back');
    } else {
      handleDebadordleInput(((input: any): DebadordleInput));
    }
  }

  const [gameOverAnimation, setGameOverAnimation] = useState(false);
  const [showShareButton, setShowShareButton] = useState(
    () => boardStorage.showShareButton === true
  );

  function startGameOverAnimation() {
    setGameOverAnimation(true);

    // don't wait for animation to write to local storage
    updateLocalStorageBoard({ showShareButton: true });

    toast(getWinMessage(rowRef.current), () => setShowShareButton(true));
  }

  // cleanup TODO: fixup the way i'm doing animations
  // TODO: create a util fn to determine letter colors, then pass to keyboard. <-- do this next
  return (
    <>
      <Board
        currentRow={currentRow}
        gameOver={gameOver}
        grid={grid}
        animations={{
          animationClass,
          resetAnimation,
          gameOverAnimation,
          startGameOverAnimation,
        }}
      />
      <Keyboard
        handleLetterClick={handleLetterClick}
        letters={getKeyboardLetterStatesFromGrid(grid)}
      />
      {showShareButton && (
        <button
          type="button"
          onClick={() => copyText(gridRef, () => toast('copied to clipboard!'))}
        >
          share
        </button>
      )}
    </>
  );
}
