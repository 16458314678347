// @flow

import type { LetterData } from './Debadordle';
import type { Element } from 'react';

import '../css/Board.css';
import Letter, { LetterState } from './Letter';

function Board({
  grid,
  currentRow,
  gameOver,
  animations,
}: {
  grid: Array<Array<?LetterData>>,
  currentRow: number,
  gameOver: boolean,
  animations?: {
    animationClass?: string,
    resetAnimation: () => void,
    startGameOverAnimation: () => void,
    gameOverAnimation: boolean,
  },
}): Element<any> {
  const gridLetters = grid.map((row, i) => (
    <div
      key={i}
      className={`Board-row ${
        i === currentRow ? animations?.animationClass ?? '' : ''
      }`}
      onAnimationEnd={animations?.resetAnimation}
    >
      {row.map((letterData, j) => (
        <Letter
          key={i.toString() + j.toString()}
          state={letterData?.letterState ?? LetterState.EMPTY}
          letter={letterData?.letter}
          column={j}
          newInput={letterData?.newInput}
          gameOver={i === currentRow - 1 && animations?.gameOverAnimation}
          startGameOverAnimation={
            i === currentRow - 1 && gameOver && j === 4
              ? animations?.startGameOverAnimation
              : null
          }
        />
      ))}
    </div>
  ));

  return <div className="Board-container">{gridLetters}</div>;
}

export default Board;
