// @flow
import Letter from '../Letter';
import { LetterState, letterStateToEmoji } from '../Letter';
import type { LetterData } from '../Debadordle';

export async function copyText(
  gridRef: { current: Array<Array<?LetterData>> },
  successFn: () => void
) {
  const grid = gridRef.current;
  if (grid == null) {
    return;
  }
  const text =
    'Debadordle\n\n' +
    grid
      .map((row) =>
        row
          .map((data) =>
            letterStateToEmoji(data?.letterState ?? LetterState.EMPTY)
          )
          .join('')
      )
      .join('\n');

  if ('clipboard' in navigator) {
    console.log('clipboard');
    await navigator.clipboard.writeText(text);
  } else {
    console.log('execCommand');
    document.execCommand('copy', true, text);
  }

  if (successFn != null) {
    successFn();
  }
}

export function getResultForGuessedLetter(
  guessedRow: Array<?LetterData>,
  colToEval: number,
  answer: string
): LetterState {
  const guessedLetter = guessedRow[colToEval]?.letter ?? '';
  let result = null;
  if (answer.charAt(colToEval) === guessedLetter) {
    result = LetterState.CORRECT;
  } else if (answer.search(guessedLetter) > -1) {
    for (let j = 0; j < 5; j++) {
      if (j === colToEval) {
        continue;
      }
      if (guessedRow[colToEval]?.letter !== answer.charAt(j)) {
        // we're only looking for other instances of this letter in the answer
        continue;
      }
      if (guessedRow[j]?.letter !== answer.charAt(j)) {
        // if the correct answer is not there, then
        // this current letter should be marked wrong_place.
        result = LetterState.WRONG_PLACE;
        break;
      }
    }
    // if the other occurences of this letter are already guessed correctly
    // then we need to say that the letter in this position is wrong
    result = result ?? LetterState.WRONG;
  } else {
    result = LetterState.WRONG;
  }
  return result;
}

export function getWinMessage(row: number): string {
  switch (row) {
    case 0:
      return 'Genius';
    case 1:
      return 'Magnificent';
    case 2:
      return 'Impressive';
    case 3:
      return 'Splendid';
    case 4:
      return 'Great';
    case 5:
      return 'Phew';
    default:
      return 'bug!';
  }
}

export function getKeyboardLetterStatesFromGrid(
  grid: Array<Array<?LetterData>>
): Map<string, LetterState> {
  const result = new Map<string, LetterState>();
  for (let i = 0; i < grid.length; i++) {
    for (let j = 0; j < grid[0].length; j++) {
      const letter = grid[i][j]?.letter;
      const letterState = grid[i][j]?.letterState ?? LetterState.EMPTY;
      if (letter == null) {
        break;
      }
      if (!result.has(letter)) {
        result.set(letter, letterState);
      } else {
        const existing = result.get(letter);
        if (
          existing === LetterState.CORRECT ||
          letterState === LetterState.CORRECT
        ) {
          result.set(letter, LetterState.CORRECT);
        } else if (
          existing === LetterState.WRONG_PLACE ||
          letterState === LetterState.WRONG_PLACE
        ) {
          result.set(letter, LetterState.WRONG_PLACE);
        }
        // if it exists in the map, but is not 'wrong place' or 'correct'
        // then there is nothing for us to update
      }
    }
  }
  return result;
}
